import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import { hillo } from 'hillo'

const entity = {
  fpZNum: {

  },
  fpSN: {},
  createdAt: {

  },
  content: {
    tableConfig: { overwrite: true },
    displayName: 'operation'
  }
}

const config = {
  load: async function (filter) {
    return (await hillo.post('ZBon.php?op=getFPZbonRecord', {})).content
  }
}

export default IKDataEntity.ModelFactory(entity, config)

export async function printItalyZbon (fpSN) {
  return (await hillo.post('ZBon.php?op=printFPZbon', {
    ...fpSN
  }))
}

export async function reprintFBZBon (item, fpSN) {
  return (await hillo.post('ZBon.php?op=reprintFPZbon', {
    fpZNum: item,
    ...fpSN
  }))
}

export async function printItalyXbon (fpSN) {
  return (await hillo.post('ZBon.php?op=printFPXbon', {
    ...fpSN
  }))
}

export async function autoSettingItalyPrinter () {
  return (await hillo.get('Printer.php?op=autoSetFPPrinterSN', {
  }))
}
