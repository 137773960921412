<template>
  <div>
    <ik-data-table
      ref="table"
      :custom-on-row-click="goto"
      :icon="'mdi-calendar-clock'"
      :model="model"
      :required-date-value="$parent.dateRange"
      :use-default-action="false"
      entity-name="cashBook"
    >
      <template #primaryButton>
        <v-btn
          color="amber lighten-4 black--text"
          elevation="0"
          @click="newAdd"
        >
          <div
            class="d-flex align-center"
          >
            <v-icon
              left
            >
              mdi-plus-circle-outline
            </v-icon>
            <template>
              <div
                class="text-truncate"
                style="max-width: 80px !important;"
              >
                {{ $t('cashBook') }}
              </div>
            </template>
          </div>
        </v-btn>
      </template>
      <template #topOnTable="{tableItems}">
        <div
          :class="$vuetify.breakpoint.mdAndUp ? 'd-flex align-center' : ''"
          class="mb-4"
          style="width: 100%;"
        >
          <v-card
            class="pa-4 d-flex flex-column"
            elevation="0"
            outlined
            style="background: white;border-radius: 24px !important;white-space: nowrap"
            width="100%"
          >
            <div class="d-flex align-center">
              <v-icon>
                mdi-arrow-up-bold-box-outline
              </v-icon>
              <div class="text-subtitle-2">
                {{ $t('totalIncome') }}
              </div>
            </div>
            <div class="text-caption">
              {{ $t('nowTimeRange') }}
            </div>
            <div class="text-h3 font-weight-black mt-4 success--text">
              {{ getTotal(tableItems.filter(it => parseFloat(it.payLogAmount) > 0)) | priceDisplay }}
            </div>
          </v-card>
          <v-card
            :class="$vuetify.breakpoint.lgAndUp ? 'ml-2' : 'mt-2'"
            class="pa-4 d-flex flex-column"
            elevation="0"
            outlined
            style="background: white;border-radius: 24px !important;white-space: nowrap"
            width="100%"
          >
            <div class="d-flex align-center">
              <v-icon>
                mdi-arrow-down-bold-box-outline
              </v-icon>
              <div class="text-subtitle-2">
                {{ $t('totalExpend') }}
              </div>
            </div>
            <div class="text-caption">
              {{ $t('nowTimeRange') }}
            </div>
            <div
              class="text-h3 font-weight-black mt-4 error--text"
            >
              {{ -1 * getTotal(tableItems.filter(it => lessThanZero(it.payLogAmount))) | priceDisplay }}
            </div>
          </v-card>
          <v-card
            :class="$vuetify.breakpoint.lgAndUp ? 'ml-2' : 'mt-2'"
            class="pa-4 d-flex flex-column"
            elevation="0"
            outlined
            style="background: white;border-radius: 24px !important;white-space: nowrap"
            width="100%"
          >
            <div class="d-flex align-center">
              <v-icon>mdi-bank</v-icon>
              <div class="text-subtitle-2">
                {{ $t('totalCash') }}
              </div>
            </div>
            <div class="text-caption">
              ({{ $t('allDate') }})
            </div>
            <div class="text-h3 font-weight-black mt-4">
              {{ currentAmount| priceDisplay }}
            </div>
          </v-card>
        </div>
      </template>
      <template #footer="{tableItems}">
        <primary-button
          text="countCash"
          @click="openCashDialog"
        />
        <primary-button
          :icon="'mdi-image-check-outline'"
          text="PreviewSheet"
          @click="exportPdf(tableItems)"
        />
      </template>
      <template
        #item.files="{item}"
      >
        <v-btn
          v-if="item.noteFile"
          :height="!$vuetify.breakpoint.lgAndUp ? '40px' : ''"
          :icon="!$vuetify.breakpoint.lgAndUp"
          :style="!$vuetify.breakpoint.lgAndUp ? 'border-radius: 12px; background: #f57c00;' : ''"
          :width="!$vuetify.breakpoint.lgAndUp ? '40px' : ''"
          color="amber lighten-4 black--text"
          elevation="0"
          small
          @click.stop="downloadFile(item)"
        >
          <template v-if="!$vuetify.breakpoint.lgAndUp">
            <v-icon color="white">
              mdi-cog
            </v-icon>
          </template>
          <template v-if="$vuetify.breakpoint.lgAndUp">
            <div
              :class="!$vuetify.breakpoint.lgAndUp ? 'text-truncate' : ''"
              :style="!$vuetify.breakpoint.lgAndUp ? 'max-width: 100px;' : ''"
            >
              {{ $t('file') }}
            </div>
          </template>
        </v-btn>
      </template>
    </ik-data-table>
    <v-dialog
      v-model="cashManageDialog"
      max-width="600px"
    >
      <v-card
        class="pa-4"
      >
        <div class="d-flex align-center">
          <div class="text-h3 font-weight-bold">
            {{ $t('cashBook') }}
          </div>
          <v-spacer />
          <div>
            <v-btn
              icon
              @click="cashManageDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="mt-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div class="text-overline">
              {{ $t('inOrOut') }}
            </div>
            <v-select
              v-model="cashbookType"
              :items="lrType"
              :rules="rules"
              dense
              hide-details
              item-text="label"
              item-value="value"
              outlined
            />
            <div class="text-overline">
              {{ $t('purpose') }}
            </div>
            <v-select
              v-model="editItem.cashAccountUsageId"
              :items="currentAccountList"
              :rules="rules"
              dense
              hide-details
              item-text="usageName"
              item-value="id"
              outlined
            />
            <div class="text-overline">
              {{ $t('TaxRate') }}
            </div>
            <v-select
              v-model="taxRate"
              :items="taxArray"
              dense
              hide-details
              item-text="displayName"
              item-value="rateValue"
              outlined
            />
            <div
              v-for="(item,index) in currentAmountList"
              :key="index"
            >
              <div class="text-overline">
                {{ item.displayName }}
              </div>
              <v-text-field
                v-model="item.amount"
                :rules="rules"
                dense
                hide-details
                outlined
              />
            </div>
            <div
              v-if="showCashBoxInfo"
              class="text-overline"
            >
              {{ $t('CashBox') }}
            </div>
            <v-select
              v-if="showCashBoxInfo"
              v-model="editItem.cashBoxId"
              :items="cashBoxList"
              :rules="rules"
              dense
              hide-details
              item-text="name"
              item-value="id"
              outlined
            />
            <div class="text-overline">
              {{ $t('note') }}
            </div>
            <v-text-field
              v-model="editItem.note"
              dense
              hide-details
              outlined
            />
            <div class="text-overline">
              {{ $t('file') }}
            </div>
            <v-file-input
              v-model="editItem.file"
              dense
              hide-details
              outlined
            />
          </v-form>
        </div>
        <v-btn
          :loading="btnLoading"
          class="primary mt-4"
          width="100%"
          @click="saveCashBook"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCashCountDialog"
      max-width="600"
    >
      <v-card
        v-if="showCashCountDialog"
        class="px-4 pb-2"
      >
        <page-sub-header>
          {{ $t('cashCalculator') }}
          <template #action>
            <template v-if="currentAmount!==total">
              <small-base-card>{{ $t('totalValue') }} {{ currentAmount | priceDisplay }}</small-base-card>
              <small-base-card>{{ $t('difference') }} {{ currentAmount - total | priceDisplay }}</small-base-card>
            </template>
          </template>
        </page-sub-header>
        <div class="text-body-1 mb-2">
          {{ $t('cashTotal') }}{{ cashTotal | priceDisplay }}
        </div>
        <div style="display: grid;grid-template-columns: repeat(3,1fr);grid-gap: 4px;">
          <v-card
            v-for="i in bigCashType"
            :key="i"
            class="pa-4"
            color="grey lighten-5"
            flat
          >
            <div class="text-h4 d-flex align-center">
              {{ i |priceDisplay }}
              <v-spacer />&times;
              {{ cashCount[i] }}
            </div>
            <v-btn
              color="red lighten-3"
              elevation="0"
              x-small
              @click="addCashAmount(i,-1)"
            >
              <div class="text-body-1">
                -1
              </div>
            </v-btn>
            <v-btn
              color="green lighten-4"
              elevation="0"
              x-small
              @click="addCashAmount(i,1)"
            >
              <div class="text-body-1">
                +1
              </div>
            </v-btn>
            <v-btn
              color="green lighten-3"
              elevation="0"
              x-small
              @click="addCashAmount(i,10)"
            >
              <div class="text-body-1">
                +10
              </div>
            </v-btn>
            <v-btn
              color="green lighten-2"
              elevation="0"
              x-small
              @click="addCashAmount(i)"
            >
              <div class="text-body-1">
                +
              </div>
            </v-btn>
          </v-card>
        </div>
        <div class="text-body-1 mb-2 mt-8">
          {{ $t('coinTotal') }}{{ coinTotal | priceDisplay }}
        </div>
        <div style="display: grid;grid-template-columns: repeat(3,1fr);grid-gap: 4px;">
          <v-card
            v-for="i in coinCashType"
            :key="i"
            class="pa-4"
            color="grey lighten-5"
            flat
          >
            <div class="text-h4 d-flex align-center">
              {{ i |priceDisplay }}
              <v-spacer />&times;
              {{ cashCount[i] }}
            </div>
            <v-btn
              color="red lighten-3"
              elevation="0"
              x-small
              @click="addCashAmount(i,-1)"
            >
              <div class="text-body-1">
                -1
              </div>
            </v-btn>
            <v-btn
              color="green lighten-4"
              elevation="0"
              x-small
              @click="addCashAmount(i,1)"
            >
              <div class="text-body-1">
                +1
              </div>
            </v-btn>
            <v-btn
              color="green lighten-3"
              elevation="0"
              x-small
              @click="addCashAmount(i,10)"
            >
              <div class="text-body-1">
                +10
              </div>
            </v-btn>
            <v-btn
              color="green lighten-2"
              elevation="0"
              x-small
              @click="addCashAmount(i)"
            >
              <div class="text-body-1">
                +
              </div>
            </v-btn>
          </v-card>
        </div>
        <v-btn
          block
          class="mt-2"
          color="green darken-4 white--text"
          elevation="0"
          x-large
          @click="showCashCountDialog=false"
        >
          <v-icon left>
            mdi-check
          </v-icon>
          {{ $t('altogether') }} {{ total | priceDisplay }}
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPdfDisplay"
      max-width="800px"
    >
      <v-card
        class="pa-4 d-flex flex-column"
      >
        <loading-provider :loading="loading">
          <div id="report">
            <div style="display: flex; align-items: center; justify-content: center;font-size: 24px">
              Kassenbuch
            </div>
            <div style="display: flex; align-items: center; justify-content: center">
              Von: {{ startDate }} Bis: {{ endDate }}
            </div>
            <div style="display: flex; align-items: center; justify-content: center">
              <div class="font-weight-bold">
                Startsaldo:
              </div>
              <v-spacer />
              <div>{{ startAmount | priceDisplay }}</div>
            </div>
            <v-simple-table>
              <thead>
                <tr>
                  <th style="text-align: start">
                    Zweck
                  </th>
                  <th style="text-align: center">
                    Datum
                  </th>
                  <th
                    v-for="(item,index) in latestTaxRateNames"
                    :key="index"
                    style="text-align: center"
                  >
                    {{ item.displayName }}
                  </th>
                  <th style="text-align: end">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in displayItems"
                  :key="index"
                >
                  <td style="text-align: start">
                    {{ item.usageName }}
                  </td>
                  <td style="text-align: center">
                    {{ item.updateTimestamp ?? '' }}
                  </td>
                  <td style="text-align: center">
                    {{ item.amountA | priceDisplay }}
                  </td>
                  <td style="text-align: center">
                    {{ item.amountB | priceDisplay }}
                  </td>
                  <td style="text-align: center">
                    {{ item.amountC | priceDisplay }}
                  </td>
                  <td style="text-align: end">
                    {{ item.payLogAmount | priceDisplay }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div style="display: flex; align-items: center; justify-content: center">
              <div class="font-weight-bold">
                Endbestand:
              </div>
              <v-spacer />
              <div>{{ endAmount | priceDisplay }}</div>
            </div>
          </div>
          <v-btn
            v-print="'#report'"
            class="mt-4 amber lighten-4 black--text"
            elevation="0"
            outlined
            width="100%"
          >
            {{ $t('pdf') }}
          </v-btn>
        </loading-provider>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDetailDialog"
      max-width="600px"
    >
      <v-card class="pa-4">
        <div class="d-flex align-center mb-4">
          <div class="text-h3 font-weight-bold">
            {{ detailInfo?.usageName }}
          </div>
          <v-spacer />
          <div>
            <v-btn
              icon
              @click="showDetailDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th
                v-for="(item,index) in latestTaxRateNames"
                :key="index"
                class="font-weight-bold text-h4"
                style="text-align: center"
              >
                {{ item.displayName }}
              </th>
              <th
                class="font-weight-bold text-h4"
                style="text-align: end"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: center">
                {{ detailInfo.amountA | priceDisplay }}
              </td>
              <td style="text-align: center">
                {{ detailInfo.amountB | priceDisplay }}
              </td>
              <td style="text-align: center">
                {{ detailInfo.amountC | priceDisplay }}
              </td>
              <td style="text-align: end">
                {{ detailInfo.payLogAmount | priceDisplay }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IkDataTable from '@/plugins/EntityFrameWork/IkDataTable.vue'
import { defaultDate } from '@/model/dataLayer/api/common/DateRepository'
import cashbook, {
  getFastCCashAmountUntilDateTime,
  getLatestTaxRateNames
} from '@/model/dataLayer/api/cashbook/cashbook'
import cashAccount from '@/model/dataLayer/api/cashbook/cashAccount'
import { forceGetSystemSetting } from '@/model/dataLayer/api/NewSettings'
import cashBox from '@/model/dataLayer/api/CashBox/CashBox'
import { getBaseAndUrlForDeviceId } from '@/model/dataLayer/service/RestaurantInfoService'
import GlobalConfig from '@/model/global/GlobalSettings'
import { getCurrentCashAmount } from '@/model/dataLayer/service/otherService'
import PrimaryButton from '@/views/AadenPages/components/PrimaryButton.vue'
import SmallBaseCard from '@/views/widget/uiComponent/SmallBaseCard.vue'
import PageSubHeader from '@/views/widget/typography/PageSubHeader.vue'
import IKUtils from 'innerken-js-utils'
import { values } from 'lodash-es'
import { groupBy, sumBy } from 'lodash'
import i18n from '@/i18n'
import dayjs from 'dayjs'
import LoadingProvider from '@/views/widget/LoadingProvider.vue'

export default {
  name: 'CashBookManage',
  data: function () {
    return ({
      detailInfo: {},
      showDetailDialog: false,
      btnLoading: false,
      startAmount: '',
      endAmount: '',
      latestTaxRateNames: [],
      displayItems: [],
      showPdfDisplay: false,
      rules: [
        v => !!v || i18n.t('ItemRequired')
      ],
      valid: true,
      taxRate: '',
      taxArray: [{ id: -1, name: 'Mix', displayName: 'Mix', rateValue: '' }],
      lrType: [{ label: i18n.t('deposit'), value: '1' },
        { label: i18n.t('withdraw'), value: '2' }],
      cashbookType: '',
      showCashCountDialog: false,
      cashCount: null,
      bigCashType: [200, 100, 50, 20, 10, 5],
      coinCashType: [2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01],
      currentAmount: -1,
      currentCashbookItem: {},
      cashBoxList: [],
      showCashBoxInfo: false,
      editItem: {},
      defaultItem: {
        amount: '',
        note: '',
        cashBoxId: '',
        cashAccountUsageId: '',
        amountA: '0',
        amountB: '0',
        amountC: '0',
        file: null
      },
      accountUsageList: [],
      cashManageDialog: false,
      dateRange: [defaultDate, defaultDate],
      model: cashbook,
      loading: false
    })
  },
  components: {
    LoadingProvider,
    PageSubHeader,
    SmallBaseCard,
    PrimaryButton,
    IkDataTable
  },
  watch: {
    '$parent.dateRange': {
      handler: function (val) {

      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    startDate () {
      return this.$parent.dateRange[0] + ' 04:00:00'
    },
    endDate () {
      return dayjs(this.$parent.dateRange[1]).add(1, 'day').format('YYYY-MM-DD') + ' 03:59:59'
    },
    currentAmountList () {
      const res = this.taxArray
      if (this.taxRate) {
        return res.filter(it => it.rateValue === this.taxRate)
      } else {
        return res.filter(it => it.id !== -1)
      }
    },
    currentAccountList () {
      return this.accountUsageList.filter(it => it.lrMark === this.cashbookType)
    },
    coinTotal () {
      return sumBy(this.coinCashType.map(it => it * this.cashCount[it]))
    },
    cashTotal () {
      return sumBy(this.bigCashType.map(it => it * this.cashCount[it]))
    },
    total () {
      return this.coinTotal + this.cashTotal
    }
  },
  async mounted () {
    this.currentAmount = await getCurrentCashAmount()
    this.accountUsageList = await cashAccount.getList()
    this.latestTaxRateNames = await getLatestTaxRateNames()
    this.taxArray = this.taxArray.concat(this.latestTaxRateNames).map(it => {
      if (it.rateValue) {
        it.displayName = (parseFloat(it.rateValue) * 100).toFixed(0) + '%'
      }
      return it
    })
    const res = await forceGetSystemSetting({
      section: 'CashBox',
      sKey: 'enabled',
      sValue: '0',
      defaultValue: '0',
      sType: 'boolean',
      minimumVersion: '1.7.825',
      sOptions: '',
      tagList: 'basic,cash,box'
    })
    if (res === '1') {
      this.showCashBoxInfo = true
      this.cashBoxList = await cashBox.getList()
    }
  },
  methods: {
    goto (item) {
      this.showDetailDialog = true
      this.detailInfo = item
      console.log(item, 'item')
    },
    async exportPdf (items) {
      this.loading = true
      this.showPdfDisplay = true
      this.startAmount = await getFastCCashAmountUntilDateTime(this.startDate)
      this.endAmount = await getFastCCashAmountUntilDateTime(this.endDate)
      const arr1 = items.filter(it => it.name.toLowerCase() === 'umsatz' || it.name.toLowerCase() === 'trinkgeld')
      const arr2 = items.filter(it => it.name.toLowerCase() !== 'umsatz' && it.name.toLowerCase() !== 'trinkgeld')
      const result = Object.entries(groupBy(arr1, 'usageName')).map(it => {
        return {
          usageName: it[0],
          payLogAmount: sumBy(it[1], (o) => {
            if (o.payLogAmount) {
              return parseFloat(o.payLogAmount)
            } else {
              return 0
            }
          }).toFixed(2),
          amountA: sumBy(it[1], (o) => {
            if (o.amountA) {
              return parseFloat(o.amountA)
            } else {
              return 0
            }
          }).toFixed(2),
          amountB: sumBy(it[1], (o) => {
            if (o.amountB) {
              return parseFloat(o.amountB)
            } else {
              return 0
            }
          }).toFixed(2),
          amountC: sumBy(it[1], (o) => {
            if (o.amountC) {
              return parseFloat(o.amountC)
            } else {
              return 0
            }
          }).toFixed(2)
        }
      })
      this.displayItems = result.concat(arr2)
      this.loading = false
    },
    async addCashAmount (amount, count = null) {
      if (!count) {
        this.showCashCountDialog = false
        count = parseInt(await IKUtils.showInput('请输入张数', 'number', ''))
        this.showCashCountDialog = true
      }
      if (this.cashCount[amount] + count >= 0) {
        this.$set(this.cashCount, amount, this.cashCount[amount] + count)
      }
    },
    openCashDialog () {
      const cashArr = [...this.bigCashType, ...this.coinCashType]
      this.cashCount = Object.fromEntries(values(cashArr).map(it => [it, 0]))
      for (const amount of cashArr) {
        this.cashCount[amount] = 0
      }
      this.showCashCountDialog = true
    },
    lessThanZero (item) {
      return parseFloat(item) < 0
    },
    getTotal (items) {
      return items.reduce((sum, i) => sum + parseFloat(i.payLogAmount), 0)
    },
    async downloadFile (item) {
      const { url } = await getBaseAndUrlForDeviceId(GlobalConfig.DeviceId)
      window.open(url + '/Resource/' + item.noteFile)
    },
    newAdd () {
      this.$refs?.form?.resetValidation()
      this.cashbookType = ''
      this.taxRate = ''
      this.editItem = this.defaultItem
      this.cashManageDialog = true
    },
    async saveCashBook () {
      this.btnLoading = true
      const currentAccount = this.accountUsageList.find(it => it.id === this.editItem.cashAccountUsageId)
      this.editItem.amountA = this.currentAmountList.find(it => it.name === 'A')?.amount ?? 0
      this.editItem.amountB = this.currentAmountList.find(it => it.name === 'B')?.amount ?? 0
      this.editItem.amountC = this.currentAmountList.find(it => it.name === 'C')?.amount ?? 0
      if (currentAccount.lrMark === '2') {
        this.editItem.amount = parseFloat(this.editItem.amount) * -1
        this.editItem.amountA = parseFloat(this.editItem.amountA) * -1
        this.editItem.amountB = parseFloat(this.editItem.amountB) * -1
        this.editItem.amountC = parseFloat(this.editItem.amountC) * -1
      }
      this.editItem.amount = parseFloat(this.editItem.amountA) + parseFloat(this.editItem.amountB) + parseFloat(this.editItem.amountC)
      await cashbook.add(this.editItem)
      this.btnLoading = false
      await this.$refs.table.reload()
      this.cashManageDialog = false
    }
  }
}
</script>

<style scoped>

</style>
