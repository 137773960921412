import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import { hillo } from 'hillo'

const entity = {
  orderId: {
    displayName: 'orderNumber'
  },
  date: {},
  progressivoInvio: {
    displayName: 'bills'
  },
  pivaPaese: {
    displayName: 'Country'
  },
  piva: {
    displayName: 'taxNumber'
  },
  denominazione: {
    displayName: 'CompanyName'
  },
  cap: {
    displayName: 'postCode'
  },
  pecSend: {
    displayName: 'Status'
  },
  sendEmail: {
    type: IKDataEntity.Types.Integer,
    form: false,
    tableConfig: {
      overwrite: true
    }
  }
}

const config = {
  load: async function (filter) {
    return (await hillo.get('FatturaPAController.php?op=getFPAList', {})).content
  }
}

export default IKDataEntity.ModelFactory(entity, config)
