import { hillo } from 'hillo'
import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'

const entity = {
  createdAt: {},
  orderId: {
    displayName: 'orderNumber'
  },
  taskStatus: {
    displayName: 'Status'
  },
  taskType: {
    displayName: 'Typ'
  },
  operation: {
    type: IKDataEntity.Types.Integer,
    form: false,
    tableConfig: {
      overwrite: true
    }
  }
}
const config = {
  load: async function () {
    return (await hillo.get('TeaMakerController.php?op=getTeaMakerTaskListLimit300', {}))
      .content
  }
}

export default IKDataEntity.ModelFactory(entity, config)
export async function getMilkTeaSettings () {
  return (await hillo.get('TeaMakerController.php?op=getTeaMakerSettings')).content
}

export async function updateMilkTeaSettings (settings) {
  return (await hillo.post('TeaMakerController.php?op=updateTeaMakerSettings', {
    settings: JSON.stringify(settings)
  }))
}

export async function cancelAllMissions () {
  return (await hillo.get('TeaMakerController.php?op=cancelFailedTask', {}))
}

export async function resendAllMissions () {
  return (await hillo.get('TeaMakerController.php?op=sendFailedTasksAgain', {}))
}

export async function resendMissionsById (taskId) {
  return (await hillo.post('TeaMakerController.php?op=forceSendTeaMakerTask', {
    taskId
  }))
}
