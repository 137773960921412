import { hillo } from 'hillo'
import GlobalConfig, { Remember } from '@/model/global/GlobalSettings'

export async function getCategoryReport (timespan) {
  const [fromDate, toDate] = timespan
  console.log(GlobalConfig, 'config')
  const res = (await hillo.get('BackendData.php?op=categoryReportV2', {
    fromDate,
    toDate
  })).content
  res.forEach(it => {
    it._langsname = it.langs.find(it => it.lang.toLowerCase() === Remember.lang.toLowerCase()).name
  })
  console.log(res, 'res')
  return res
}
