<template>
  <v-container
    class="error-page fill-height"
    tag="section"
  >
    <v-row
      class="text-center"
      justify="center"
    >
      <v-col cols="auto">
        <h1 class="text-h2 font-weight-black">
          404
        </h1>

        <div class="text-h4 mt-4">
          {{ $t('subscriptionWrong') }} :(
        </div>
        <div class="mb-5 text-body-1">
          {{ $t('callUs') }}
        </div>

        <v-btn
          v-if="!allActiveCodes.includes(ProductCodes.openDevice)"
          class="amber lighten-4 black--text"
          depressed
          @click="gotoOpen"
        >
          {{ $t('OpenImmediately') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getActiveProductCode } from '@/model/dataLayer/api/SaaS/user'
import { loadProducts, ProductCodes } from '@/model/dataLayer/api/SaaS/product'
import { doPayment } from '@/model/dataLayer/api/SaaS/SaaSUtils'
import IKUtils from 'innerken-js-utils'
import { getCurrentSaaSOrderInfo } from '@/views/SaaS/SaaSGuide'

export default {
  name: 'NeedOpenDevicePage',
  computed: {
    ProductCodes () {
      return ProductCodes
    }
  },
  data () {
    return {
      allActiveCodes: []
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async reload () {
      this.allActiveCodes = await getActiveProductCode()
    },
    getCorrectPriceForProduct (product, overrideMonthly = null) {
      const isOneTime = product.prices.some(it => it.type === 'one_time')
      const monthly = overrideMonthly ?? this.billedMonthly
      if (isOneTime) {
        product.prices[0].count = product.count ?? 1
        product.prices[0].realTotalPrice = product.prices[0].count * product.prices[0].unit_amount
        return product.prices[0]
      } else {
        if (monthly) {
          return product.prices.find(it => it.interval === 'month')
        } else {
          return product.prices.find(it => it.interval !== 'month')
        }
      }
    },
    async gotoOpen () {
      const enProducts = await loadProducts(1)
      const enNeedToBuyProductsList = enProducts.filter(it => it.metadata.code === ProductCodes.openDevice)
      enNeedToBuyProductsList.forEach(it => {
        it.realPrice = this.getCorrectPriceForProduct(it)
      })
      IKUtils.showLoading(true)
      const result = await doPayment(
        enNeedToBuyProductsList,
        getCurrentSaaSOrderInfo().storeId)
      if (result) {
        location.reload()
      }
    }
  }
}
</script>

<style lang="sass">
.error-page
  h1
    font-size: 12rem
</style>
