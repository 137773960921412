<template>
  <div style="width: 100%">
    <v-text-field
      v-model="minUnitName"
      dense
      outlined
    />
    <div v-if="minUnitName">
      <div
        v-for="(r, index) in unitArray"
        :key="r.key"
        class="d-flex align-center mb-4"
      >
        <v-text-field
          v-model="r.name"
          dense
          hide-details
          outlined
          @blur="fillIfEmpty"
          @input="emitUpdate"
        />
        <div class="mx-2">
          =
        </div>
        <v-text-field
          v-model="r.nextLevelFactor"
          dense
          hide-details
          min="0"
          outlined
          type="number"
          @blur="fillIfEmpty"
          @input="emitUpdate"
        />
        <div class="mx-2">
          -
        </div>
        {{ unitArray?.[index - 1]?.name ?? minUnitName }}
      </div>
    </div>
  </div>
</template>

<script>
import IKUtils from 'innerken-js-utils'

export default {
  props: {
    value: {}
  },
  data () {
    return {
      internalValue: [],
      counter: 0,
      defaultUnitInfo: { name: '', nextLevelFactor: '' },
      unitArray: [],
      minUnitName: ''
    }
  },
  computed: {
    result () {
      return [{ name: this.minUnitName, nextLevelFactor: 1 }, ...this.unitArray.filter(it => it.name || it.nextLevelFactor)]
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (newValue) {
        if (newValue && newValue.length > 0) {
          const list = IKUtils.deepCopy(newValue)
          const minUnit = list.shift()
          this.minUnitName = minUnit.name
          this.unitArray = list
        }
      }
    }
  },
  methods: {
    fillIfEmpty () {
      const empty = this.unitArray.filter(it => !it.name && !it.nextLevelFactor)
      if (empty.length === 0) {
        this.unitArray.push(this.createRow())
      } else if (empty.length > 1) {
        this.unitArray = this.unitArray.filter(it => it.name || it.nextLevelFactor)
        this.unitArray.push(this.createRow())
      }
    },
    createRow () {
      const row = IKUtils.deepCopy(this.defaultUnitInfo)
      row.key = this.counter++
      return row
    },
    emitUpdate () {
      this.$emit('input', this.result)
    }
  },
  mounted () {
    this.fillIfEmpty()
  }
}
</script>

<style scoped>
</style>
