<template>
  <v-container
    style="height: 100vh;overflow-y: scroll"
  >
    <div
      class="d-flex flex-wrap align-center"
    >
      <div class="d-flex align-center">
        <back-button @close-event="closeDialog" />
        <page-header class="py-5 pb-6">
          #{{ info.id }}
        </page-header>
      </div>
    </div>
    <div class="d-flex">
      <div
        class="flex-grow-1"
        style="width: 100%"
      >
        <v-card
          v-for="(item,index) in orderInfo"
          :key="index"
          class="pa-4 mt-2"
          elevation="0"
          width="100%"
        >
          <div class="d-flex">
            <div class="font-weight-bold">
              {{ item.code }}
            </div>
            <div class="d-flex flex-column ml-4">
              <div class="text-h4 font-weight-bold ">
                {{ item.name }}
              </div>
              <div>
                {{ item.modInfoValue }}
              </div>
            </div>
            <v-spacer />
            <div class="d-flex flex-column text-no-wrap text-right">
              <div>* {{ item.count }}</div>
              <div>{{ item.realPrice | priceDisplay }}</div>
            </div>
          </div>
        </v-card>
      </div>
      <v-spacer />
      <div
        :class="!$vuetify.breakpoint.mdAndUp ? 'flex-grow-1 order-0 mt-2' : 'ml-8 flex-shrink-0'"
        :style="!$vuetify.breakpoint.mdAndUp ? 'position: relative; width: 100%;' : 'position: sticky; top: 0; width: 300px;'"
        class="d-flex flex-column"
      >
        <v-card
          class="pa-4 mt-2"
          elevation="0"
        >
          <div class="text-h4 px-4">
            {{ $t('Information') }}
          </div>
          <v-divider class="my-2" />
          <div class="px-4">
            <div class="d-flex align-center justify-center">
              <div class="text-caption font-weight-bold">
                {{ $t('receivingStatus') }}:
              </div>
              <v-spacer />
              <v-icon>
                {{ info.processed ? 'mdi-check' : 'mdi-close' }}
              </v-icon>
            </div>
            <div class="d-flex align-center justify-center">
              <div class="text-caption font-weight-bold">
                {{ $t('paymentStatus') }}:
              </div>
              <v-spacer />
              <v-icon>
                {{ info.paymentStatus === 1 ? 'mdi-check' : 'mdi-close' }}
              </v-icon>
            </div>
            <div class="d-flex align-center justify-center">
              <div class="text-caption font-weight-bold">
                {{ $t('PaymentType') }}:
              </div>
              <v-spacer />
              <div class="text-caption font-weight-bold">
                {{ showCurrentPayMethod(addressInfo?.payMethodId) }}
              </div>
            </div>
            <div class="d-flex">
              <div class="text-caption font-weight-bold">
                {{ $t('totalPay') }}:
              </div>
              <v-spacer />
              <div class="text-caption font-weight-bold">
                {{ info.totalPrice | priceDisplay }}
              </div>
            </div>
            <div
              v-for="(item,index) in takeawayWebInfo"
              :key="index"
            >
              <div class="d-flex">
                <div class="text-caption font-weight-bold">
                  {{ item }}:
                </div>
                <v-spacer />
                <div class="text-caption font-weight-bold">
                  {{ takeawayMetaData[item] }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <v-card
          class="pa-4 mt-2"
          elevation="0"
        >
          <address-info-block :address-info="addressInfo" />
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import BackButton from '@/views/widget/uiComponent/BackButton'
import PageHeader from '@/views/widget/typography/PageHeader'
import AddressInfoBlock from '@/views/widget/commonLogic/base/AddressInfoBlock'
import payment from '@/model/dataLayer/api/common/Payment'
import { keys } from 'lodash'

export default {
  name: 'TakeawayOrderDetailInfo',
  components: { AddressInfoBlock, PageHeader, BackButton },
  props: {
    info: {}
  },
  data: function () {
    return ({
      payMethodList: []
    })
  },
  computed: {
    takeawayMetaData () {
      return this.addressInfo.metaData
    },
    takeawayWebInfo () {
      return keys(this.info.detailInfo.addressInfo.metaData).filter(it => it !== 'paid')
    },
    paidInfo () {
      return this.info
    },
    orderInfo () {
      const res = this.info.detailInfo.order
      return res.map(it => {
        it.modInfoValue = it.displayApply
          .map((x) => {
            const price = x.priceInfo > 0 ? ' (' + x.priceInfo + ')' : ''
            return x.value + price
          }).join(', ')
        return it
      })
    },
    addressInfo () {
      return this.info.detailInfo.addressInfo ?? []
    }
  },
  async mounted () {
    this.payMethodList = await payment.getList(true)
  },
  methods: {
    showCurrentPayMethod (id) {
      return this.payMethodList.find(it => it.id === id).name
    },
    async closeDialog () {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>
