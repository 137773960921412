<script>
import IkDataTable from '@/plugins/EntityFrameWork/IkDataTable.vue'
import LoadingProvider from '@/views/widget/LoadingProvider.vue'
import AppList from '@/model/dataLayer/api/takeaway/AppList'
import DatePickerInput from '@/views/AadenPages/dashboard/components/DatePickerInput.vue'
import { defaultDate } from '@/model/dataLayer/api/common/DateRepository'

export default {
  name: 'TakeawayOrderByApp',
  data: function () {
    return ({
      dateRange: [defaultDate, defaultDate],
      model: AppList,
      loading: false
    })
  },
  components: {
    DatePickerInput,
    LoadingProvider,
    IkDataTable
  }
}
</script>

<template>
  <div>
    <loading-provider :loading="loading">
      <ik-data-table
        :entity-name="'App' + $t('Order')"
        :icon="'mdi-calendar-clock'"
        :model="model"
        :required-date-value="dateRange"
        :use-default-action="false"
      >
        <template #footer="{}">
          <date-picker-input
            v-model="dateRange"
            card-class="pa-3"
            color="grey lighten-3"
          />
        </template>
      </ik-data-table>
    </loading-provider>
  </div>
</template>

<style scoped>

</style>
