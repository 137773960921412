import hillo from 'hillo'

export async function getFranceSettings () {
  return (await hillo.get('Complex.php?op=getFiskalTrustSettings', {})).content
}

export async function getUnsignedOrderCount () {
  return (await hillo.get('Complex.php?op=getUnsignedOrderCount', {})).content.count
}

export async function gotoSignLate () {
  return (await hillo.post('Complex.php?op=signLate', {}, {
    showLoading: false,
    silent: true
  }))
}
