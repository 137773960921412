<template>
  <div
    class="flex-grow-0"
    style="width: 100%"
  >
    <loading-provider
      :loading="loading"
      style="width: 100%"
    >
      <div
        class="mb-4"
        style="display: grid; grid-template-columns: repeat(6, minmax(0, 1fr)); width: 100%; height: 300px; overflow-y: scroll"
      >
        <v-lazy
          v-for="c in emojis"
          :key="c"
          min-height="48"
        >
          <v-card
            :color="model === c ? 'green' : ''"
            class="pa-1"
            elevation="0"
            @click="setModel(c)"
          >
            <v-responsive :aspect-ratio="1">
              <v-card
                class="d-flex align-center justify-center text-body-1"
                color="grey-lighten-4"
                elevation="0"
                height="100%"
                rounded
                width="100%"
              >
                {{ c }}
              </v-card>
            </v-responsive>
          </v-card>
        </v-lazy>
      </div>
    </loading-provider>
  </div>
</template>

<script>
import { emojis } from '@/views/AadenPages/extraModules/storage/ResourceChildrenPage/emojis'
import LoadingProvider from '@/views/widget/LoadingProvider'

export default {
  components: {
    LoadingProvider
  },
  props: {
    value: {},
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      model: null
    }
  },
  mounted () {
    this.loading = false
    if (!this.model && this.required) {
      this.model = emojis[0]
    }
  },
  methods: {
    setModel (value) {
      this.model = value
      this.$emit('input', this.model)
    }
  },
  computed: {
    emojis () {
      return emojis
    }
  }
}
</script>

<style scoped>
/* Your styles here */
</style>
