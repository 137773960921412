<script>
import BaseForm from '@/views/widget/INK/BaseForm/BaseForm.vue'
import { getMilkTeaSettings, updateMilkTeaSettings } from '@/model/dataLayer/api/MilkTeaSetting'

export default {
  name: 'MilkTeaSetting',
  components: { BaseForm },
  data: function () {
    return ({
      milkTeaInfo: null
    })
  },
  computed: {
    schemas () {
      return [
        {
          key: 'enabled',
          name: 'enabled'
        },
        {
          key: 'thirdStoreId',
          name: 'thirdStoreId',
          required: false,
          componentProps: {
            rules: []
          }
        },
        {
          key: 'channel',
          name: 'channel',
          required: false,
          componentProps: {
            rules: []
          }
        },
        {
          key: 'appKey',
          name: 'appKey',
          required: false,
          componentProps: {
            rules: []
          }
        },
        {
          key: 'appSecret',
          name: 'appSecret',
          required: false,
          componentProps: {
            rules: []
          }
        },
        {
          key: 'baseUrl',
          name: 'baseUrl',
          required: false,
          componentProps: {
            rules: []
          }
        }
      ]
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async updateSetting (info) {
      console.log('321')
      await updateMilkTeaSettings(info)
    },
    async reload () {
      this.milkTeaInfo = await getMilkTeaSettings()
      console.log(this.milkTeaInfo, 'milkTeaInfo')
    }
  }
}
</script>

<template>
  <base-form
    :editing-object="milkTeaInfo"
    :is-dialog="false"
    :schema="schemas"
    @submit="updateSetting"
  />
</template>

<style scoped>

</style>
