import hillo from 'hillo'

export async function getItalyConfigs () {
  return (await hillo.get('FatturaPAController.php?op=getFPASettings', {})).content
}

export async function updateItalyConfigs (config) {
  return hillo.post('FatturaPAController.php?op=updateFPASettings', {
    settings: JSON.stringify(config)
  })
}

export async function getAllFPAList () {
  return hillo.get('FatturaPAController.php?op=getFPAList', {})
}

export async function getFPAListByOrderId (id) {
  return (await hillo.get('FatturaPAController.php?op=getFPAList', { orderId: id })).content
}

export async function createFPA (info) {
  return hillo.post('FatturaPAController.php?op=createFPA', { data: JSON.stringify(info) })
}

export async function updateFPA (info) {
  return hillo.post('FatturaPAController.php?op=updateFPA', { data: JSON.stringify(info) })
}

export async function sendFPAEmail (id) {
  return hillo.post('FatturaPAController.php?op=sendFPAEmail', { id })
}
